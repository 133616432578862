<!-- 派单弹窗组件 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'派单':'派单'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="100px">

      <!-- 选项 -->
      <el-row>
        <div class="xuanxianglist">
          <div class="xuanxianglistactive" v-for="(item,index) in list" :key="index"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
            {{item.name}}
          </div>
        </div>
      </el-row>
      <!--司机-->
      <el-row v-if="isShow == 0">
        <!-- 搜索 -->
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="16">
            <el-input v-model="where.keyword" placeholder="请输入关键字" prefix-icon="el-icon-search" clearable></el-input>
          </el-col>
          <!--外协司机不展示距离筛选-->
          <el-col :span="4" v-if="sijiisShow != 3">
            <div class="xialakuang">
              <el-select
                style="background: #E9EAF1 !important;"
                clearable
                v-model="where.range"
                placeholder="请选择距离"
                class="ele-fluid">
                <el-option
                  v-for="(item) in dict.type.distance"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="text-align: center;">
              <el-button type="primary" @click="search">搜索</el-button>
            </div>
          </el-col>
        </el-row>

        <!--司机选择 -->
        <el-row style="margin-top: 15px;border-bottom: 1px solid #e3e4e9;">
          <el-col :span="15">
            <div class="sijilist">
              <div class="sijilistactive" v-for="(item,index) in sijiList" :key="index" :class="{sijilistactive1:index==sijiisShow}" @click="sijiClick(item,index)">
                {{item.name}}
              </div>
            </div>
          </el-col>
          <!--判断下外协司机不展示状态筛选-->
          <el-col :span="9" v-if="sijiisShow != 3">
            <div class="statusList">
              <div class="statusListactive" v-for="(item,index) in StatusList" :key="index" :class="{statusListactive1:index==statusisShow}" @click="statusClick(item,index)">
                {{item.name}}
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- 司机列表 -->
        <el-row>
          <div class="sijiradio">
            <!--<el-radio-group v-model="radio">-->
            <!--  <el-radio :label="1" style="margin: 20px;">-->
            <!--    <div class="listactive">-->
            <!--      <div>-->
            <!--        <span style="width: 10px;height: 10px;background: #25eb61;border-radius: 50%; display: inline-block;margin-right: 20px;"></span>-->
            <!--        <span style="font-size: 16px;font-weight: 500;color: #2d2f47;">-->
            <!--          <span>吴海军</span>-->
            <!--          <span style="margin-left: 20px;">苏JH80E9</span>-->
            <!--          <span style="margin-left: 20px;">23.55km</span>-->
            <!--        </span>-->
            <!--      </div>-->
            <!--      <div style="margin-top: 20px;">-->
            <!--        <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;">重庆市酉阳县钟多街道何家坝路261号</span>-->
            <!--        <img src="@/assets/images/home/dianhua.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;margin-left: 20px;">-->
            <!--        <span style="display: inline-block; width: 340px;color: #FFFFFF;">占位</span>-->
            <!--      </div>-->
            <!--    </div>-->
            <!--  </el-radio>-->
            <!--</el-radio-group>-->

            <div style="margin: 20px; display: flex;align-items: center;cursor: pointer;" v-for="(item,index) in sijilistList" :key="index" >
              <div style="margin-right: 20px;">
                <img v-if="index==sijilistlistisShow" src="@/assets/images/home/xuanzhonganniu.png" alt="">
                <img v-else src="@/assets/images/home/weixuanzhonganniu.png" alt="">
              </div>
              <div class="listactive" :class="{listactive1:index==sijilistlistisShow}" @click="sijilistListClick(item,index)">
                <div>
                  <span style="width: 10px;height: 10px;background: #25eb61;border-radius: 50%; display: inline-block;margin-right: 20px;"></span>
                  <span style="font-size: 16px;font-weight: 500;color: #2d2f47;">
                      <span>{{item.realname}}</span>
                      <span style="margin-left: 20px;">{{item.license_plate_number}}</span>
                      <span style="margin-left: 20px;" v-if="sijiisShow != 3">{{item.distance}}km</span>
                      <span style="margin-left: 20px;" v-if="item.driver_price != '' && sijiisShow != 3">预计 <span style="color: red;">{{item.driver_price}}</span> 元</span>
                    </span>
                </div>
                <div style="margin-top: 20px;">
                  <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;" v-if="sijiisShow != 3">当前坐标所在地：{{item.id_address}}</span>
                  <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;margin-left: 15px;">{{item.phone}}</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;margin-left: 20px;">
                  <span style="display: inline-block; width: 140px;color: #FFFFFF;">占位</span>
                </div>
                <div v-if="sijiisShow == 2">
                  <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;">归属：{{item.company_name}}</span>
                </div>
              </div>
            </div>

            <el-empty description="暂无数据" v-if="sijilistList.length == 0"></el-empty>
            <div style="margin-bottom: 10px;" v-if="sijilistList.length > 0">
              <el-pagination
                style="text-align: center; margin-top: 20px"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="where.limit"
                :current-page="where.page"
                @size-change="ChangeSize"
                @current-change="currentChange"
                background>
              </el-pagination>
            </div>
          </div>
        </el-row>
      </el-row>

      <!--服务商-->
      <el-row v-if="isShow == 1">
        <!-- 搜索 -->
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="20">
            <el-input v-model="fuwushangwhere.company_name" placeholder="请输入服务商名称" prefix-icon="el-icon-search"></el-input>
          </el-col>
          <!--<el-col :span="4">-->
          <!--  <div class="xialakuang">-->
          <!--    <el-select-->
          <!--      style="background: #E9EAF1 !important;"-->
          <!--      clearable-->
          <!--      v-model="fuwushangwhere.range"-->
          <!--      placeholder="请选择距离"-->
          <!--      class="ele-fluid">-->
          <!--      <el-option-->
          <!--        v-for="(item) in dict.type.distance"-->
          <!--        :label="item.label"-->
          <!--        :value="item.value"-->
          <!--      />-->
          <!--    </el-select>-->
          <!--  </div>-->
          <!--</el-col>-->
          <el-col :span="4">
            <div style="text-align: center;">
              <el-button type="primary" @click="fuwushangsearch">搜索</el-button>
            </div>
          </el-col>
        </el-row>

        <!--服务商 -->
        <el-row style="margin-top: 15px;border-bottom: 1px solid #e3e4e9;">
          <!--<el-col :span="24">-->
          <!--  <div class="sijilist">-->
          <!--    <div class="sijilistactive" style="width: 90px;" v-for="(item,index) in fuwushanglist" :key="index" :class="{sijilistactive1:index==fuwushangisShow}" @click="fuwushangisShowClick(item,index)">-->
          <!--      {{item.name}}-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->
        </el-row>

        <!-- 服务商 -->
        <el-row>
          <div class="sijiradio">
            <div style="margin: 20px; display: flex;align-items: center;cursor: pointer;" v-for="(item,index) in fuwushanglistList" :key="index" >
              <div style="margin-right: 20px;">
                <img v-if="index==fuwushanglistlistisShow" src="@/assets/images/home/xuanzhonganniu.png" alt="">
                <img v-else src="@/assets/images/home/weixuanzhonganniu.png" alt="">
              </div>
              <div class="listactive" :class="{listactive1:index==fuwushanglistlistisShow}" @click="fuwushanglistListClick(item,index)">
                <div>
                  <span style="font-size: 16px;font-weight: 500;color: #2d2f47;">{{item.company_name}}</span>
                </div>
                <div style="margin-top: 20px;">
                  <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;margin-right: 10px;">{{item.detailed_address}}</span>
                  <img style="width: 16px;height: 16px; vertical-align: middle; margin-right: 20px;" src="@/assets/images/home/juli.png" alt="">
                  <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;">{{item.distance}}km</span>
                  <img src="@/assets/images/home/dianhua.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;margin-left: 20px;">
                  <span style="display: inline-block; width: 140px;color: #FFFFFF;">占位</span>
                </div>
              </div>
            </div>
            <el-empty description="暂无数据" v-if="fuwushanglistList.length == 0"></el-empty>
            <div style="margin-bottom: 10px;" v-if="fuwushanglistList.length > 0">
              <el-pagination
                style="text-align: center; margin-top: 20px"
                layout="total, sizes, prev, pager, next, jumper"
                :total="fuwushangtotal"
                :page-size="fuwushangwhere.limit"
                :current-page="fuwushangwhere.page"
                @size-change="fuwushangChangeSize"
                @current-change="fuwushangcurrentChange"
                background>
              </el-pagination>
            </div>
          </div>
        </el-row>
      </el-row>


      <!--附近修理厂-->
      <el-row v-if="isShow == 2">
        <!-- 搜索 -->
        <!--<el-row :gutter="15" style="margin-top: 15px;">-->
        <!--  <el-col :span="20">-->
        <!--    <el-input v-model="searchvalue" placeholder="请输入关键词" prefix-icon="el-icon-search"></el-input>-->
        <!--  </el-col>-->
        <!--  <el-col :span="4">-->
        <!--    <div style="text-align: center;">-->
        <!--      <el-button type="primary" @click="xiulichangsearch">搜索</el-button>-->
        <!--    </div>-->
        <!--  </el-col>-->
        <!--</el-row>-->
        <!-- 附近修理厂 -->
        <el-row>
          <div class="sijiradio">
            <div style="margin: 20px; display: flex;align-items: center;cursor: pointer;" v-for="(item,index) in xiulichanglistlist" :key="index" >
              <div class="listactive" :class="{listactive1:index==xiulichanglistlistisShow}" @click="xiulichanglistListClick(item,index)">
                <div style="display: flex;justify-content: space-between;">
                  <span style="font-size: 16px;font-weight: 500;color: #2d2f47;">{{item.name}}</span>
                  <span>
                    <img style="width: 16px;height: 16px; vertical-align: middle; margin-right: 20px;" src="@/assets/images/home/juli.png" alt="">
                    <span style="font-size: 14px;font-weight: 400;color: #868792; vertical-align: middle;">{{item.juli}}</span>
                  </span>
                </div>
                <div style="margin-top: 10px;" v-if="item.tel != ''">
                  <span style="font-size: 16px;font-weight: 400;color: #868792; vertical-align: middle">{{item.tel}}</span>
                  <!--<img src="@/assets/images/home/dianhua.png" alt="" style="width: 20px;height: 20px;vertical-align: middle;margin-left: 20px;">-->
                  <!--<span style="display: inline-block; width: 140px;color: #FFFFFF;">占位</span>-->
                </div>
                <div style="margin-top: 10px;">
                  <span style="font-size: 16px;font-weight: 500;color: #2d2f47;">{{item.address}}</span>
                </div>
              </div>
            </div>
            <el-empty description="暂无数据" v-if="xiulichanglistlist.length == 0"></el-empty>
            <div style="margin-bottom: 10px;" v-if="xiulichanglistlist.length > 0">
              <el-pagination
                style="text-align: center; margin-top: 20px"
                layout="total, sizes, prev, pager, next, jumper"
                :total="xiulichanglistlisttotal"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="xiulichanglistlistwhere.limit"
                :current-page="xiulichanglistlistwhere.page"
                @size-change="xiulichanglistlistChangeSize"
                @current-change="xiulichanglistlistcurrentChange"
                background>
              </el-pagination>
            </div>
          </div>
        </el-row>
        <div id="container" style="display: none;"></div>
        <div id="panel" style="display: none;"></div>
      </el-row>
    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <!--&& sijiisShow != 1-->
      <el-button
        v-if="isShow != 2"
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        v-auths="[`${$config.uniquePrefix}orderModule:toBeDispatched:grouporder`]"
        @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
//引入的接口
import {
  getclone_driver,
  getget_cooperation,
  getget_send_driver,
  getoutsource_driver,
  getshare_driver,
  Saverepair, Savesend
} from "@/api/orderModule";
// 引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader';
// 获取高德地图的key
import setting from "@/config/config";
// 引入将米转换为km
import {kmUnit} from '@/utils'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 经度纬度
      location: JSON.parse(localStorage.getItem('location')),

      //司机
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 3 // 每页显示条目个数
      },
      // 总条目数
      total: 0,

      //司机相关
      list:[
        {
          name:'司机'
        },
        {
          name:'服务商'
        },
        {
          name:'附近修理厂'
        }
      ],
      isShow:0,
      StatusList:[
        {
          name:'空闲',
          status:'1'
        },
        {
          name:'忙碌',
          status:'2'
        },
        {
          name:'休息',
          status:'0'
        },
      ],
      statusisShow:0,

      sijiList:[
        {
          name:'自营司机'
        },
        {
          name:'共享司机'
        },
        {
          name:'合作司机'
        },
        {
          name:'外协司机'
        }
      ],
      sijiisShow:0,
      sijilistList:[],
      sijilistlistisShow: 100,

      //司机id
      driver_id: '',

      //司机身份
      //自营 self
      //共享 share
      driver_identity:'self',

      //服务商id
      facilitator_id:'',




      //服务商相关
      // 表格搜索条件
      fuwushangwhere: {
        page: 1, // 当前页数
        limit: 3 // 每页显示条目个数
      },
      // 总条目数
      fuwushangtotal: 0,
      fuwushanglist:[
        {
          name:'自营服务商'
        },
        {
          name:'合作服务商'
        },
        {
          name:'平台服务商'
        },
      ],
      fuwushangisShow:0,

      fuwushanglistList:[],
      fuwushanglistlistisShow:100,


      // 修理厂
      // 表格搜索条件
      xiulichanglistlistwhere: {
        page: 1, // 当前页数
        limit: 5 // 每页显示条目个数
      },
      // 总条目数
      xiulichanglistlisttotal: 0,
      // 数组
      xiulichanglistlist:[],
      xiulichanglistlistisShow:100,
      //搜索
      searchvalue:'',
      //选择的数据
      selectitem:null,


      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map:null,

    };
  },
  watch: {

  },
  mounted() {
    console.log(this.dict)
    console.log(this.location,'当前的经度纬度');

    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    //获取服务商
    if(this.isShow == 1){
      this.getFuwushang();
    }

    //获取附近修理厂
    if(this.isShow == 2){
      this.initMap();
    }

    //获取自营司机列表
    if(this.sijiisShow == 0){
      this.getList();
    }

    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }

  },
  methods: {
    //获取自营司机司机列表
    getList(){
      // this.where.status = this.StatusList[this.statusisShow].status;
      // 合作司机没有状态
      if(this.sijiisShow == 2){
        delete this.where.status
      }else {
        this.where.status = this.StatusList[this.statusisShow].status;
      }
      getget_send_driver(this.data.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    //获取共享司机
    getGongxiang(){
      this.where.status = this.StatusList[this.statusisShow].status;
      getshare_driver(this.data.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    //获取合作司机
    getWaixie(){
      this.where.status = this.StatusList[this.statusisShow].status;
      getoutsource_driver(this.data.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },
    //获取外协司机
    getgetclone_driver(){
      // 外协司机没有状态
      if(this.sijiisShow == 3){
        // 没有状态筛选
        delete this.where.status
        // 没有距离筛选
        delete this.where.range
      }else {
        this.where.status = this.StatusList[this.statusisShow].status;
      }
      getclone_driver(this.data.id,this.where).then(res => {
        console.log(res)
        this.total = res.data.count;
        this.sijilistList = res.data.list;
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.isShow == 0){
            var data = {
              driver_id: this.driver_id,
              driver_identity: this.driver_identity
            }
            // 用于判断下如果时外协司机去掉身份
            if(this.sijiisShow == 3){
              delete data.driver_identity
            }else {
              data.driver_identity = this.driver_identity
            }
            // 判断是否没有选择司机
            if(this.driver_id == ''){
              this.$message.error('请选择司机')
            }else {
              Savesend(this.data.id,data).then(res => {
                if(res.code === 200){
                  this.loading = true;
                  this.$message.success(res.msg);
                  this.updateVisible(false);
                  this.$emit('freshen');
                  this.loading = false;
                }else {
                  this.$message.error(res.msg)
                }
              }).catch(e => {
                console.log(e.msg)
              })
            }
          }else if(this.isShow == 1){
            var data = {
              facilitator_id: this.facilitator_id,
            }
            if(this.facilitator_id == ''){
              this.$message.error('请选择服务商')
            }else {
              Savesend(this.data.id,data).then(res => {
                if(res.code === 200){
                  this.loading = true;
                  this.$message.success(res.msg);
                  this.updateVisible(false);
                  this.$emit('freshen');
                  this.loading = false;
                }else {
                  this.$message.error(res.msg)
                }
              }).catch(e => {
                console.log(e.msg)
              })
            }
          }else if(this.isShow == 2){
            if(this.selectitem == null){
              this.$message.error('请选择修理厂')
            }else {
              let data = {
                odd: this.data.order_id,
                repair_gid: this.selectitem.id,
                repair_name: this.selectitem.name,
                repair_site: this.selectitem.repair_site,
                phone: this.selectitem.tel,
                linkman: '',
                location: this.selectitem.location1
              };
              Saverepair(this.data.id,data).then(res => {
                if(res.code === 200){
                  this.loading = true;
                  this.$message.success(res.msg);
                  this.updateVisible(false);
                  this.$emit('freshen');
                  this.loading = false;
                }else {
                  this.$message.error(res.msg)
                }
              }).catch(e => {
                console.log(e.msg)
              })
            }
          }

        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择司机 还是服务商 还是修理厂
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;

      //选择司机
      //获取自营司机列表
      if(this.isShow == 0){
        this.getList();
        this.sijiisShow = 0;
      }

      //获取服务商
      if(this.isShow == 1){
        this.getFuwushang();
      }

      //获取附近修理厂
      if(this.isShow == 2){
        this.initMap();
      }
    },

    // 点击搜索
    search(){
      if(this.driver_identity == 'self'){
        this.getList();
      }else if(this.driver_identity == 'share'){
        this.getGongxiang();
      }else if(this.driver_identity == 'waixie'){
        this.getgetclone_driver();
      } else {
        this.getWaixie();
      }
    },

    //司机
    //选择状态
    statusClick(item,index){
      this.statusisShow = index;
      // 自营司机
      if(this.sijiisShow == 0){
        this.where.status = this.StatusList[this.statusisShow].status;
        this.getList();
      }else if(this.sijiisShow == 1){
        //共享司机
        this.where.status = this.StatusList[this.statusisShow].status;
        this.getGongxiang()
      }else if(this.sijiisShow == 2){
        //合作司机
        this.where.status = this.StatusList[this.statusisShow].status;
        this.getWaixie()
      }else if(this.sijiisShow == 3){
        //外协司机
        this.getgetclone_driver()
      }
    },
    //司机类型选择
    sijiClick(item,index){
      this.sijiisShow = index;
      // 自营司机
      if(this.sijiisShow == 0){
        this.getList();
        //司机身份
        this.driver_identity = 'self'
      }else if(this.sijiisShow == 1){
        //共享司机
        this.getGongxiang()
        //司机身份
        this.driver_identity = 'share'
      }else if(this.sijiisShow == 2){
        //合作司机
        this.getWaixie();
      }else if(this.sijiisShow == 3){
        //外协司机
        this.getgetclone_driver()
        //司机身份
        this.driver_identity = 'waixie'
      }
    },
    //司机列表选择
    sijilistListClick(item,index){
      this.sijilistlistisShow = index;
      //司机id
      this.driver_id = item.id;
    },


    //服务商
    fuwushangisShowClick(item,index){
      this.fuwushangisShow = index;
      //获取合作服务商
      if(this.fuwushangisShow == 1){
        this.getFuwushang();
      }else {
        this.fuwushanglistList = [];
      }
    },
    fuwushanglistListClick(item,index){
      this.fuwushanglistlistisShow = index;
      //服务商id
      this.facilitator_id = item.id;
    },
    //获取服务商
    getFuwushang(){
      this.fuwushangwhere.oid = this.data.id;
      // oid: this.data.id,
      getget_cooperation(this.fuwushangwhere).then(res => {
        console.log(res)
        this.fuwushangtotal = res.data.count;
        this.fuwushanglistList = res.data.list;
      })
    },
    // 服务商当前页数改变事件
    fuwushangcurrentChange(val) {
      console.log("当前页数", val)
      this.fuwushangwhere.page = val;
      this.getFuwushang();
    },
    fuwushangChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.wuwushangwhere.limit = val;
      this.getFuwushang();
    },
    // 搜索
    fuwushangsearch(){
      this.getFuwushang();
    },


    //修理厂
    xiulichanglistListClick(item,index){
      this.xiulichanglistlistisShow = index;
      this.selectitem = item;
      console.log(this.selectitem);
      this.selectitem.repair_site = [];
      this.selectitem.repair_site.push(item.pname,item.cityname,item.adname,item.address);
      this.selectitem.location1 = [];
      this.selectitem.location1.push(item.location.lng,item.location.lat);
    },
    //搜索事件
    // xiulichangsearch(){
    //
    // },
    // 服务商当前页数改变事件
    xiulichanglistlistcurrentChange(val) {
      console.log("当前页数", val)
      this.xiulichanglistlistwhere.page = val;
      this.initMap(this.xiulichanglistlistwhere.page,this.xiulichanglistlistwhere.limit);
    },
    xiulichanglistlistChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.xiulichanglistlistwhere.limit = val;
      this.initMap(this.xiulichanglistlistwhere.page,this.xiulichanglistlistwhere.limit);
    },
    // 初始化高德地图 获取附近修理厂
    initMap(page,limit){
      AMapLoader.load({
        key: setting.key,// 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:['AMap.PlaceSearch','AMap.GeometryUtil'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container", {
          resizeEnable: true
        });
        var placeSearch = new AMap.PlaceSearch({
          // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
          // city: '010',
          type:['汽车维修'],
          pageSize: limit, // 单页显示结果条数
          pageIndex: page, // 页码
          map: this.map, // 展现结果的地图实例
          panel: "panel", // 结果列表将在此容器中进行展示。
          autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        let that = this;
        let location = [];
        // 判断下单双地址 single为单地址
        if(that.data.odd_even_address == 'single'){
          location[0] = that.data.starting_point_coord[0];
          location[1] = that.data.starting_point_coord[1];
        }else {
          location[0] = that.data.destination_coord[0];
          location[1] = that.data.destination_coord[1];
        }
        console.log(location,'位置坐标')
        // 获取当前订单的经度纬度
        var cpoint = location; //中心点坐标
        placeSearch.searchNearBy('', cpoint, 50000, function(status, result) {
          // 查询成功时，result即对应匹配的POI信息
          console.log(result)
          that.xiulichanglistlist = result.poiList.pois;
          that.xiulichanglistlisttotal = result.poiList.count;
          that.xiulichanglistlist.forEach(item => {
            let p1 = [location[0], location[1]];
            let p2 = [item.location.lng, item.location.lat];
            // 返回 p1 到 p2 间的地面距离，单位：米
            let dis = parseInt(AMap.GeometryUtil.distance(p1, p2));
            let km = kmUnit(dis);
            item.juli = km;
          })
          console.log(that.xiulichanglistlist);
        });
      }).catch(e=>{
        console.log(e);
      })
    },



  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{

  .xuanxianglistactive{
    cursor: pointer;
    width: 120px;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin: 10px;
    margin-top: 0;
    display: inline-block;
  }
  .xuanxianglistactive:hover{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

}

.statusList{
  .statusListactive{
    display: inline-block;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    font-size: 14px;
    font-weight: 400;
    color: #868792;
  }
  // css选择器 第一个
  .statusListactive:first-child{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  //最后一个
  .statusListactive:last-child{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .statusListactive:hover{
    background: #000000;
    border-color: #000000;
    color: #FFFFFF;
  }
  .statusListactive1{
    background: #000000;
    border-color: #000000;
    color: #FFFFFF;
  }
}

.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
}
.listactive1{
  border: 1px solid #fea837;
}
</style>
